exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-competitions-nitro-ai-tsx": () => import("./../../../src/pages/competitions/nitroAI.tsx" /* webpackChunkName: "component---src-pages-competitions-nitro-ai-tsx" */),
  "component---src-pages-competitions-nitro-nlp-tsx": () => import("./../../../src/pages/competitions/nitroNLP.tsx" /* webpackChunkName: "component---src-pages-competitions-nitro-nlp-tsx" */),
  "component---src-pages-conference-2021-2022-nnlp-tsx": () => import("./../../../src/pages/conference/2021_2022/nnlp.tsx" /* webpackChunkName: "component---src-pages-conference-2021-2022-nnlp-tsx" */),
  "component---src-pages-conference-2022-2023-nnlp-tsx": () => import("./../../../src/pages/conference/2022_2023/nnlp.tsx" /* webpackChunkName: "component---src-pages-conference-2022-2023-nnlp-tsx" */),
  "component---src-pages-conference-tsx": () => import("./../../../src/pages/conference.tsx" /* webpackChunkName: "component---src-pages-conference-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../../../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */),
  "component---src-pages-summer-school-tsx": () => import("./../../../src/pages/summerSchool.tsx" /* webpackChunkName: "component---src-pages-summer-school-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-workshops-2021-2022-nitro-nlp-tsx": () => import("./../../../src/pages/workshops/2021_2022/nitroNLP.tsx" /* webpackChunkName: "component---src-pages-workshops-2021-2022-nitro-nlp-tsx" */),
  "component---src-pages-workshops-2022-2023-nitro-nlp-tsx": () => import("./../../../src/pages/workshops/2022_2023/nitroNLP.tsx" /* webpackChunkName: "component---src-pages-workshops-2022-2023-nitro-nlp-tsx" */),
  "component---src-pages-workshops-2023-2024-nitro-nlp-tsx": () => import("./../../../src/pages/workshops/2023_2024/nitroNLP.tsx" /* webpackChunkName: "component---src-pages-workshops-2023-2024-nitro-nlp-tsx" */),
  "component---src-pages-workshops-nitro-ai-tsx": () => import("./../../../src/pages/workshops/nitroAI.tsx" /* webpackChunkName: "component---src-pages-workshops-nitro-ai-tsx" */),
  "component---src-pages-workshops-nitro-nlp-tsx": () => import("./../../../src/pages/workshops/nitroNLP.tsx" /* webpackChunkName: "component---src-pages-workshops-nitro-nlp-tsx" */)
}

